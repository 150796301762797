import { Component, OnInit, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.page.html',
  styleUrls: ['./authentication.page.scss'],
})

export class AuthenticationPage implements OnInit
{
  stepView = 'default'; // view state keyword
  isLoader = false; // view loader
  isWeb: boolean; // check if web or not (app)
  customValues: object;
  isVersion: boolean; // app version ok ?
  isOnline: boolean; // network connected ?
  deviceHeight = ''; // device app height (px)
  initDefaultView: any; // init props for default component
  isScrolled = false; // check if scrolled page

  constructor(
    public platform: Platform,
    public common: CommonService,
    private router: Router
  ){
    this.platform.backButton.subscribeWithPriority(10, () => {
      switch (this.stepView){
        case 'default':
        break;
        case 'connection':
        case 'first-connection-method':
          localStorage.removeItem('authProviderRunning');
          this.stepView = 'default';
        break;
        case 'first-connection':
          this.stepView = 'first-connection-method';
        break;
        case 'first-connection-signin':
          this.stepView = 'first-connection';
        break;
        case 'password-code':
          this.stepView = 'password';
        break;
        case 'password-update':
          this.stepView = 'password-code';
        break;
        case 'password-confirm':
          this.stepView = 'password-update';
        break;
      }
    });
  }

  ionViewWillEnter(){
    this.isLoader = false;
    this.initDefaultView = {
      token: (localStorage.getItem('token_invitation') !== null) ? true : false,
      password: this.router.url.includes('password')
    };
    if (this.platform.getQueryParam('firstcnx') !== null){
      this.stepView = 'first-connection';
    }
  }

  // get step view keyword
  getChildStepView(key: string){
    this.stepView = key;
  }

  // get loader keyword
  getChildLoader(key: boolean){
    this.isLoader = key;
  }

  // get step view keyword with values
  getChildStepViewWithValues(response: {event: string, value: object}){
    this.stepView = response.event;
    this.customValues = null;
    this.customValues = response.value;
  }

  ngOnInit(){
    if (this.platform.getQueryParam('token') !== null) {
      localStorage.setItem('token_invitation', this.platform.getQueryParam('token'));
      this.stepView = 'default';
    }

    if (this.router.url.includes('password')) {
      this.stepView = 'password';
    }

    this.isWeb = (this.common.isNative()) ? false : true;

    if (this.common.isWebDevice()){ this.deviceHeight = 'height: ' + window.innerHeight + 'px'; }

    this.initDefaultView = {
      token: (localStorage.getItem('token_invitation') !== null) ? true : false,
      password: this.router.url.includes('password')
    };
  }

   // emit when loader acticity
  loaderManager(activity: boolean){
    this.isLoader = activity;
  }

  // process with app scrolling
  initScroll(event: any){
    if (event.detail.scrollTop === 0){ this.isScrolled = false; }
    else { this.isScrolled = true; }
  }
}
