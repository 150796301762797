import {Component, NgZone} from '@angular/core';
import {Platform} from '@ionic/angular';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Deeplinks} from '@awesome-cordova-plugins/deeplinks/ngx';
import {Router} from '@angular/router';
import {AuthenticationPage} from './authentication/authentication.page';
import SmartBanner from 'smart-app-banner';
import {CommonService} from './services/common.service';
import {VersionService} from './services/version.service';
import {AuthenticationService} from './services/authentication.service';
import {AndroidNotch} from '@awesome-cordova-plugins/android-notch/ngx';
import {register} from 'swiper/element/bundle';
import {SplashScreen} from '@capacitor/splash-screen';
import {Network} from '@capacitor/network';

register(); // Utilisé pour lancer le swiper.js

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  constructor(
    private platform: Platform,
    private commonService: CommonService,
    private deeplinks: Deeplinks,
    private router: Router,
    private zone: NgZone,
    private version: VersionService,
    private authService: AuthenticationService,
    private androidNotch: AndroidNotch
  ){
    this.initializeApp();
    // setInterval(() => {
    //   this.swUpdate.checkForUpdate();
    // } , 21600);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.listenConnection();
      if (this.commonService.isNative()) {
        if (this.platform.is('android')) {
          const style = document.documentElement.style;
          style.setProperty('--notch-inset-top', '25px');
          StatusBar.setOverlaysWebView({overlay: true});
          StatusBar.setBackgroundColor({color: '#33000000'});
          this.androidNotch.getInsetTop().then(px => {
            if (px > 0){
              style.setProperty('--notch-inset-top', px + 'px');
            }
          }).catch((error: any) => console.log('Error: ', error));
        } else {
          StatusBar.setOverlaysWebView({overlay: true});
          StatusBar.setStyle({style: Style.Light});
        }
        SplashScreen.hide();
      //  this.setupDeeplinks();
      }
    });
  }

  // checkUpdate() {
  //   if (!this.commonService.isNative() && this.swUpdate.isEnabled) {
  //     this.swUpdate.available.subscribe(async () => {
  //       console.log("Nouvelle version disponible.");
  //     });
  //   }
  // }

  private listenConnection(): void {
    if (!navigator.onLine) { this.router.navigate(['/warning/offline']); }
    Network.addListener('networkStatusChange', status => {
      if (!status.connected) {
        this.router.navigate(['/warning/offline']);
      }
    });

    if (this.commonService.isNative()){
      this.version.checkVersion().catch(_ => this.router.navigate(['/warning/version']));
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    if (this.platform.is('mobileweb')) {
    //  this.setupSmartBanner();
    }
  }

  setupSmartBanner() {
    this.authService.isLoggedIn.subscribe((value) => {
      if (value) {
    // tslint:disable-next-line:no-unused-expression
        new SmartBanner({
          daysHidden: 10,   // days to hide banner after close button is clicked (defaults to 15)
          daysReminder: 20, // days to hide banner after "VIEW" button is clicked (defaults to 90)
          appStoreLanguage: 'fr', // language code for the App Store (defaults to user's browser language)
          title: 'Espace client CAFPI ',
          author: 'Cafpi',
          button: 'Voir',
          store: {
            ios: 'Disponible sur l\'App Store',
            android: 'Disponible sur Google Play'
          },
          price: {
            ios: 'GRATUIT',
            android: 'GRATUIT'
          },
          // force: 'android' // Uncomment for platform emulation
        });
      }
    });
  }

  setupDeeplinks() {
    this.deeplinks.route({
      '/authentication': AuthenticationPage
    }).subscribe(
      match => {
        const internalPath = '/' + match.$route + '/' + match.$args.pass;
        this.zone.run(() => {
          this.router.navigateByUrl(internalPath);
        });
      },
      nomatch => {
      }
    );
  }
}
