import {inject, Injectable} from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { SignInWithApple, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { Auth, signInWithPopup, signInWithCredential, getAdditionalUserInfo } from '@angular/fire/auth';
import { debounceTime } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
//import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // Propriété observable BehaviorSubject pour donner l'information
  // aux observateurs qu'on est loggé ou pas (par défaut à false)
  isLoggedIn = new BehaviorSubject<boolean>(true);

  userData: any;
  private aFAuth: Auth = inject(Auth);

  constructor(
    private http: HttpClient,
    private common: CommonService,
    private router: Router
    ) {
    //  localStorage.clear();

      this.checkState();
    }


    checkState() {
      if (this.common.isNative()) {
        //
      } else {
        //
      }
      // this.nativeCheckState();
      // this.authCheckState();
      this.checkUser();
    }

    /**
     * Si un objet user est donné en paramètre, alors la fonction l'enregistre et autorise l'authentification de l'utilisateur.
     * Si null est donné en paramètre, alors la fonction supprime le localStorage et deconnecte l'utilisateur.
     * @param user object
     */
    handleUserStateChange(user, token?: string) {
      this.userData = user;
      if (user && token) {
        const now = new Date().getTime();
        localStorage.setItem('user', JSON.stringify(this.userData));
        localStorage.setItem('datemaj', now.toString());
        localStorage.setItem('projectID', this.userData.project.id);
        localStorage.setItem('requestedDocuments', JSON.stringify(this.userData.requestedDocuments));
        localStorage.setItem('jwt', token);
        this.isLoggedIn.next(true);
      } else {
        localStorage.removeItem('user');
        localStorage.removeItem('datemaj');
        localStorage.removeItem('projectID');
        localStorage.removeItem('sessionID');
        localStorage.removeItem('user_is_cafpi_auth');
        localStorage.removeItem('requestedDocuments');
        localStorage.removeItem('jwt');
        localStorage.removeItem('summaryStepActived');
        localStorage.removeItem('authProviderRunning');
        localStorage.removeItem('webAppView');
        localStorage.removeItem('privilegeView');
        localStorage.removeItem('upn');
        this.isLoggedIn.next(false);
      }
    }

    authCheckState() {
      if (localStorage.getItem('user_is_cafpi_auth') == null || !localStorage.getItem('user_is_cafpi_auth')) {
        this.aFAuth.onAuthStateChanged(user => {
          this.handleUserStateChange(user, localStorage.getItem('jwt'));
        });
      }
    }

  /* nativeCheckState() {
      return this.nativeFirebase.registerAuthStateChangeListener((user) => {
        console.log('nativeCheckStat');
        this.handleUserStateChange(user);
      });
    }*/

    checkUser() {
      if (localStorage.getItem('user') == null) {
        this.handleUserStateChange(null);
      } else {
        if (localStorage.getItem('user') === 'undefined') {
          this.handleUserStateChange(null);
        } else {
          const user = JSON.parse(localStorage.getItem('user'));

          if (user && user.user != null) {
            this.handleUserStateChange(user, localStorage.getItem('jwt'));
          }  else {
            this.handleUserStateChange(null);
          }
        }
        }
    }

    /**
     * Appel à l'API WEB afin d'enregistrer un nouveau compte.
     * @param upn string
     * @param password string
     */
    registerToCafpi(upn, password) {
      return this.connectToCafpi(upn, null, password, true);
    }

    /**
     * Fonction qui permet la connexion à l'api WEB.
     * Retourne les données liées aux projets de l'utilisateur.
     * @param upn string
     * @param provider enum ['cafpi', 'google', 'facebook']
     * @param password string
     */
    connectToCafpi(upn, provider, password = null, isRegistering = false) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token_invitation');
        const data = {upn, provider, token_invitation: token, password};
        const url = environment.cafpi.base_url + (isRegistering ? environment.cafpi.auth.register.url : environment.cafpi.auth.login.url);

        this.http.post(url, data,  {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            Authorization: 'Bearer ' + environment.cafpi.auth.authToken
          }
        }).pipe(debounceTime(500)).toPromise()
        .then((responseQuery: any) => {
          const response: any = responseQuery.data;
          // tslint:disable-next-line: no-shadowed-variable
          const token: string = responseQuery.token;

          if (response.success !== true) {
            if (!response.return_key) {
            response.return_key = 'unknown_error';
            response.message = 'Une erreur est survenue. Veuillez réessayer ultérieurement.';
            }
            this.handleUserStateChange(null);
            reject(response);
          } else {

            this.http.get(environment.cafpi.base_url + '/api/userreference', {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
              }
            }).pipe(debounceTime(500)).toPromise()
            // tslint:disable-next-line: no-shadowed-variable
            .then((response: any) => {
              if (response.data.success && response.data.success === false) {
                const response = {return_key: 'unknown_error', message: 'Une erreur est survenue. Veuillez réessayer ultérieurement.'};
                reject(response);
                this.handleUserStateChange(null);
              } else {
                localStorage.removeItem('token_invitation');
                this.handleUserStateChange(response.data, token);
                resolve(true);
              }
            });

          }
        }).catch((err: HttpErrorResponse) => {
          const response = {return_key: 'unknown_error', message: 'Une erreur est survenue. Veuillez réessayer ultérieurement.'};
          reject(response);
          this.handleUserStateChange(null);
        });
      });
    }

    /**
     * Authentication web via provider
     * @param provider Provider
     */
  webFirebaseAuth(provider, providerName) {
    return new Promise((resolve, reject) => {
      signInWithPopup(this.aFAuth, provider)
      .then((data) => {
        const email = data.user.email || getAdditionalUserInfo(data).profile.email;
        if (localStorage.hasOwnProperty('authProviderRunning')) {
          localStorage.setItem('authProviderRunning', localStorage.getItem('authProviderRunning') + email);
          resolve(true);
        } else {
          this.connectToCafpi(email, providerName).catch(error => reject(error));
        }
      })
      .catch((error) => {
        this.handleUserStateChange(null);
        reject(false);
      });
    });
  }

  /**
   * Authentification de l'utilisateur via facebook
   */
  facebookAuth(first: boolean) {
    return new Promise((resolve, reject) => {
      if (this.common.isNative()) {
        reject(false); // TODO: Voir pour un plugin capacitor
        // this.fb.login(['email', 'public_profile'])
        // .then((response: FacebookLoginResponse) => {
        //   // tslint:disable-next-line:new-parens
        //   const credentials = firebase.auth.FacebookAuthProvider.credential(response.authResponse.accessToken);
        //   signInWithCredential(this.aFAuth, credentials)
        //   .then((data) => {
        //     const email = data.user.email || getAdditionalUserInfo(data).profile.email;
        //     if (first) {
        //       localStorage.setItem('authProviderRunning', 'facebook:' + email);
        //       resolve(true);
        //     } else {
        //       this.connectToCafpi(email, 'facebook').catch(error => reject(error));
        //     }
        //   })
        //   .catch((error) => {
        //     this.handleUserStateChange(null);
        //   });
        // }).catch(error => resolve(false));
      } else {
        if (first) {
          localStorage.setItem('authProviderRunning', 'facebook:');
        }
        const provider = new firebase.auth.FacebookAuthProvider();
        this.webFirebaseAuth(provider, 'facebook')
          .then(data => resolve(true))
          .catch(error => {
            reject(error);
          });
      }
    });
  }

  /**
   * Authentification de l'utilisateur via Apple
   */
  appleAuth(first: boolean) {
    return new Promise((resolve, reject) => {
      if (this.common.isNative()) {
        SignInWithApple.authorize({
          clientId: '',
          redirectURI: ''
        })
          .then((appleCredential: SignInWithAppleResponse) => {
            let credential = null;
            try {
              credential = new firebase.auth.OAuthProvider('apple.com').credential(
                appleCredential.response.identityToken
              );
            } catch (e) {
              console.log(e);
              reject(e);
            }
            if (credential !== null) {
              signInWithCredential(this.aFAuth, credential)
                .then((data) => {
                  if (first) {
                    localStorage.setItem('authProviderRunning', 'apple:' + data.user.email);
                    resolve(true);
                  } else {
                    this.connectToCafpi(data.user.email, 'apple').catch(error => {console.log(error); reject(error); });
                  }
                })
                .catch((error) => {
                  this.handleUserStateChange(null);
                  reject(error);
                });
            }
          })
          .catch((error) => {
            if (error.error === 'PLUGIN_ERROR') {
              alert('Pour utiliser la connexion via Apple, vous devez mettre à jour votre téléphone à la version iOS 13 minimum.');
            }
            console.error(error);
            this.handleUserStateChange(null);
            reject(error);
          });
      } else {
        if (first) {
          localStorage.setItem('authProviderRunning', 'apple:');
        }
        const provider = new firebase.auth.OAuthProvider('apple.com');
        provider.setCustomParameters({
          // Localize the Apple authentication screen in French.
          locale: 'fr'
        });
        this.webFirebaseAuth(provider, 'apple')
          .then((data) => {
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  }

  facebookAuthConfirm(email: string) {
    return new Promise((resolve, reject) => {
      this.connectToCafpi(email, 'facebook').then(resp => {
        resolve(true);
      }).catch(error => reject(error));
    });
  }

  appleAuthConfirm(email: string) {
    return new Promise((resolve, reject) => {
      this.connectToCafpi(email, 'apple').then(resp => {
        resolve(true);
      }).catch(error => reject(error));
    });
  }

  // /**
  //  * Authentification de l'utilisateur via google
  //  */
  // googleAuth() {
  //   return new Promise((resolve, reject) => {
  //     if (this.common.isNative()) {
  //       this.googlePlus.login({ webClientId: '896906717065-te24v9e9bmokcpar24hrhrtg5g6n2ao3.apps.googleusercontent.com' })
  //       .then((response) => {
  //         const { accessToken, accessSecret } = response;
  //         const credentials = accessSecret ? firebase.auth.GoogleAuthProvider
  //         .credential(accessToken, accessSecret) : firebase.auth.GoogleAuthProvider
  //             .credential(accessToken);

  //         this.aFAuth.signInWithCredential(credentials)
  //         .then((data) => {
  //           this.connectToCafpi(data.user.email, 'google').catch(error => reject(error));
  //         })
  //         .catch((error) => {
  //           this.handleUserStateChange(null);
  //         });
  //       });
  //     } else {
  //       const provider = new firebase.auth.GoogleAuthProvider();
  //       this.webFirebaseAuth(provider, 'google').catch(error => reject(error));
  //     }
  //   });
  // }

  /**
   * Authentification de l'utilisateur via CAFPI
   */
  cafpiAuth(credentials) {
    return new Promise((resolve, reject) => {
      this.connectToCafpi(credentials.email.trim(), 'cafpi', credentials.password).catch(error => reject(error));
    });
  }

  /**
   * Permet la déconnexion firebase
   */
  logout() {
      this.aFAuth.signOut();
      this.handleUserStateChange(null);

      return Promise.resolve();
  }

  // On vérifie que l'utilisateur a une ou plusieurs références
  checkUserHasReference() {
    return localStorage.getItem('user') != null;
  }

  checkJWTExpDate() {
    return new Promise(async (resolve) => {
      if (localStorage.getItem('jwt') == null) {
        resolve(false);
      } else {
        const tokenData: any = jwt_decode(localStorage.getItem('jwt'));
        if (moment().isAfter(moment.unix(tokenData.expired_date))) {
          resolve(false);
        }
      }

      resolve(true);
    });
  }

  lostPasswordCheckEmail(email: string) {
    return new Promise((resolve, reject) => {
      const url: string = environment.cafpi.base_url + environment.cafpi.lost_password.check_email;
      localStorage.setItem('upn', email.trim());
      const data = {upn: email.trim()};
      this.makeCafpiAPICall(url, data, `Bearer ${environment.cafpi.auth.authToken}`).then(response => {
        if (response !== true) {
          reject(false);
        } else {
          resolve(true);
        }
      }).catch(_ => {
        reject(false);
      });
    });
  }

  lostPasswordCheckVerificationCode(verificationCode: string) {
    return new Promise((resolve, reject) => {
      const url: string = environment.cafpi.base_url + environment.cafpi.lost_password.check_verification_code;
      const data = {upn: localStorage.getItem('upn'), verification_code:  verificationCode};
      this.makeCafpiAPICall(url, data, `Bearer ${environment.cafpi.auth.authToken}`).then((response: any) => {
        if (response.success !== true) {
          reject(response);
        } else {
          resolve(true);
        }
      }).catch(_ => {
        reject(false);
      });
    });
  }

  lostPasswordUpdatePassword(newPassword: String) {
    return new Promise((resolve, reject) => {
      const url: string = environment.cafpi.base_url + environment.cafpi.lost_password.update_password;
      const upn = localStorage.getItem('upn');
      const data = {upn: localStorage.getItem('upn'), new_password:  newPassword};
      this.makeCafpiAPICall(url, data, `Bearer ${environment.cafpi.auth.authToken}`).then(response => {
        if (response !== true) {
          reject(false);
        } else {
          localStorage.removeItem('upn');
          resolve(true);
        }
      }).catch(_ => {
        reject(false);
      });
    });
  }

  makeCafpiAPICall(url: string, data?, authToken?) {
    return new Promise((resolve, reject) => {

      this.http.post(url, data,  {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          Authorization: authToken// 'Bearer ' + environment.cafpi.auth.authToken
        }
      }).pipe(debounceTime(500)).toPromise()
      .then((response: any) => {
        resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
}
}
