import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';

import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { DocumentScanner } from '@awesome-cordova-plugins/document-scanner/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { AndroidNotch } from '@awesome-cordova-plugins/android-notch/ngx';
import { provideFirebaseApp, initializeApp, } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { CustomErrorHandler } from './services/error-handler.service';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { TokenInterceptor } from './interceptors/header.interceptor';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import {CommonService} from './services/common.service';

// import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
    declarations: [AppComponent],
    imports: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        provideDatabase(() => getDatabase()),
        provideFirestore(() => getFirestore()),
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule
        // ServiceWorkerModule.register('ngsw-worker.js', { scope: './', registrationStrategy: 'registerImmediately' })
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandler
        },
        CommonService,
        File,
        FileOpener,
        DocumentScanner,
        LaunchNavigator,
        AndroidNotch,
        DocumentViewer,
        Deeplinks,
        AppVersion,
        SafariViewController,
        Chooser,
        NativeGeocoder,
        OpenNativeSettings,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
