import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
  } from '@angular/common/http';
import { Observable, from} from 'rxjs';
import { CommonService } from '../services/common.service';
import {Device} from '@capacitor/device';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    VERSION_NUMBER = '1.0.11';

    constructor(private commonService: CommonService) {}

        /**
         * Fonction qui permet d'intercepter un appel HTTP
         * @param request Requete initiale
         * @param next Permet de passer au handler suivant
         */
        intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            return from(this.addHeaders(request, next));
        }

        /**
         * Fonction qui ajoute des headers a la requete
         * Cette fonction est obligatoire, car le numero de version retourne une promise,
         * c'est donc le seul moyen pour prendre la promise et retourner la requete sous forme d'observable
         * @param request Requete initiale
         * @param next Permet de passer au handler suivant
         */
        async addHeaders(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
            const device = this.commonService.getDeviceSource();
            const info = await Device.getInfo();
            const headers: any = {};
            headers.appType = device + ' ' + info.osVersion;
            headers.appSessionID = localStorage.getItem('sessionID') || this.commonService.generateSessionID();
            headers.appCorrelationID = this.commonService.generateGUID();

            const appProjectID = await localStorage.getItem('projectID');
            if (appProjectID) {
                headers.appProjectID = appProjectID;
            }

            if (this.commonService.isNative()) {
                headers.appVersion = this.VERSION_NUMBER;
            }

            request = request.clone({
                setHeaders: headers
              });

            return next.handle(request).toPromise();
        }
}
