import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { File as NativeFile } from '@awesome-cordova-plugins/file/ngx';
import { LaunchNavigator, LaunchNavigatorOptions } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';
import { Geolocation } from '@capacitor/geolocation';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { ToastController } from '@ionic/angular';
import {BehaviorSubject} from "rxjs";

declare var cordova: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private uploadingState = new BehaviorSubject<boolean>(false);

  constructor(
    private platform: Platform,
    private file: NativeFile,
    private launchNavigator: LaunchNavigator,
    private appVersion: AppVersion,
    private http: HttpClient,
    private openNativeSettings: OpenNativeSettings,
    private toastController: ToastController
  ){
    const savedState = localStorage.getItem('isUploading') === 'true';
    this.uploadingState = new BehaviorSubject<boolean>(savedState);
  }

  /**
   * Détermine si on est en natif
   */
  isNative(){
    return (this.platform.is('ios') || this.platform.is('android')) && !( this.platform.is('desktop') || this.platform.is('mobileweb'));
  }

  /**
   * Détermine si on est en web non natif
   */
  isWebNoDevice(){
    return (this.platform.is('desktop') || (this.platform.is('mobileweb') && !this.platform.is('ios') && !this.platform.is('android')));
  }

   /**
   * Détermine si on est en web natif
   */
  isWebDevice(){
    return (!this.platform.is('desktop') && this.platform.is('mobileweb') && (this.platform.is('ios') || this.platform.is('android')));
  }

  /**
   * Détermine si on est en natif android
   */
  isNativeAndroid(){
    return this.platform.is('android') && !(this.platform.is('desktop') || this.platform.is('mobileweb'));
  }

    /**
     * Détermine si on est en natif iOS
     */
  isNativeIOS(){
    return this.platform.is('ios') && !(this.platform.is('desktop') || this.platform.is('mobileweb'));
  }

  // device desktop
  isDesktop(){
    return this.platform.is('desktop') || (this.platform.is('mobileweb') && !(this.platform.is('ios') || this.platform.is('android')));
  }

  // device android + webmobile
  isAndroidWeb(){
    return this.platform.is('android') && this.platform.is('mobileweb');
  }

  // device ios + webmobile
  isIosWeb(){
    return this.platform.is('ios') && this.platform.is('mobileweb');
  }

  setUploadingState(isUploading: boolean) {
    this.uploadingState.next(isUploading);
    localStorage.setItem('isUploading', isUploading.toString());
  }

  getUploadingState(): boolean {
    return this.uploadingState.getValue();
  }

  getNativeDowloadFolder() {
    if (this.platform.is('ios')) {
      return this.file.documentsDirectory;
    } else if (this.platform.is('android')) {
      return this.file.externalApplicationStorageDirectory;
    }

    return null;
  }

  readFileAsDataURL(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        file.src = e.target.result;
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  // Ouvre App de type Maps pour les itinéraires
  openNavigatorApp(latitude: number, longitude: number, location: string){
    Geolocation.getCurrentPosition({ timeout: 5000, enableHighAccuracy: true }).then((resp) => {
      const options: LaunchNavigatorOptions = {
        start: [resp.coords.latitude, resp.coords.longitude]
      };
      if (latitude === 0 || longitude === 0){
        this.launchNavigator.navigate(location, options);
      } else {
        this.launchNavigator.navigate([latitude, longitude], options);
      }
    }).catch((error) => {
      if (this.isNativeIOS) {
        if (error.code === 1) {
          alert('Veuillez autoriser la localisation dans les paramètres de votre appareil');
          this.redirectToSettings('locations');
        }
      } else if (this.isNativeAndroid) {
        if (error.message === 'Illegal Access') {
          alert('Veuillez autoriser la localisation');
          this.redirectToSettings('location');
        } else if (error.message === 'Timeout expired') {
          alert('Veuillez activer la localisation sur votre appareil.');
        }
      }
    });
  }

  /**
   * Redirige l'utilisateur vers les paramètres, en fonction du paramètre page
   * @param page string
   */
  redirectToSettings(page: string) {
    this.openNativeSettings.open(page);
  }

  /**
   * html parser (article, video)
   */

  htmlBuildChapeau(chapeau: string){
    return '<p class="intro">' + this.htmlParseRender(chapeau) + '</p>';
  }

  htmlBuildBody(sections: Array<any>){
    let html = '';
    let li = '';
    sections.forEach(section => {
      const parts = section.Texte.split('\n');
      parts.forEach(part => {
        if (part !== ''){
          if (part.indexOf('- ') === 0){
            let content = this.htmlParseTag(part.substring(2), '**', '<strong>', '</strong>');
            content = this.htmlParseTag(content, '~~', '<u>', '</u>');
            content = this.htmlParseTag(content, '*', '<i>', '</i>');
            li += '<li>' + this.htmlParseRender(content) + '</li>';
          }
          else {
            if (li !== ''){
              html += '<ul>' + li + '</ul>';
              li = '';
            }
            if (part.indexOf('###### ') === 0) { html += '<h6>' + this.htmlParseRender(part.substring(5)) + '</h6>'; }
            else if (part.indexOf('##### ') === 0) { html += '<h5>' + this.htmlParseRender(part.substring(5)) + '</h5>'; }
            else if (part.indexOf('#### ') === 0) { html += '<h4>' + this.htmlParseRender(part.substring(5)) + '</h4>'; }
            else if (part.indexOf('### ') === 0) { html += '<h3>' + this.htmlParseRender(part.substring(5)) + '</h3>'; }
            else if (part.indexOf('## ') === 0) { html += '<h2>' + this.htmlParseRender(part.substring(3)) + '</h2>'; }
            else if (part.indexOf('# ') === 0) { html += '<h2>' + this.htmlParseRender(part.substring(2)) + '</h2>'; }
            else {
              let content = this.htmlParseTag(part, '**', '<strong>', '</strong>');
              content = this.htmlParseTag(content, '~~', '<u>', '</u>');
              content = this.htmlParseTag(content, '\*', '<i>', '</i>');
              html += '<p>' + this.htmlParseRender(content) + '</p>';
            }
          }
        }
      });
      if (li !== ''){
        html += '<ul>' + li + '</ul>';
        li = '';
      }
    });
    return html;
  }

  htmlParseTag(html: string, search: string, begin: string, end: string){
    const seq = html.split(search);
    if (seq.length < 2) { return html; }
    else {
      let newhtml = '';
      let start = false;
      let index = 0;
      seq.forEach(s => {
        if (s === ''){
          if (start) {
            newhtml += end;
            start = false;
          } else if (index < seq.length - 1) {
            newhtml += begin;
            start = true;
          }
        } else {
          if (start) {
            newhtml += s + end;
            start = false;
          }
          else { newhtml += s; }
        }
        index++;
      });
      if (search === '*') { }
      return newhtml;
    }
  }

  htmlParseRender(html: string){
    return html.replace(/\’/g, '\'').replace(/\s\:/g, '&nbsp;:').replace(/\s\?/g, '&nbsp;?').replace(/\s\€/g, '&nbsp;€').replace('<br><br>', '');
  }

  /**
   * Retourne la source du device, formate pour etre utilise dans un header HTTP
   */
  getDeviceSource() {
    let device: string;

    if (this.platform.is('desktop') && !this.platform.is('mobileweb')) {
        device = 'Web desktop';
    } else {
        device = this.platform.is('mobileweb') ? 'Web mobile ' : 'App ';

        if
        (this.platform.is('android')) {
            device += 'Android';
        } else if (this.platform.is('ios')) {
            device += 'iOS';
        }
    }

    return device;
}

generateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

generateSessionID() {
  const guid = this.generateGUID();
  localStorage.setItem('sessionID', guid);
  return guid;
}

  /* privilege */

  savePrivilege(noMail: boolean = false, noPhone: boolean = false)
  {
    return new Promise((resolve, reject) => {
      const url = environment.cafpi.base_url + '/api/user-consent-v2';

      this.http.post(url, {noMail, noPhone}, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('jwt')
          }
      }).pipe(debounceTime(500)).toPromise()
      .then((response: {success: boolean, message: string}) => {
          if (response.success !== true) {

              reject(response.message);
          } else {
            resolve(true);
          }
      }).catch(_ => {
        reject('Une erreur est survenue, veuillez réessayer');
      });
  });
  }

  getStaticMap(parameters) {
    const apiKey = this.isWebDevice ? environment.google_maps.webApiKey : environment.google_maps.apiKey;
    return `https://maps.googleapis.com/maps/api/staticmap?${parameters}&key=${apiKey}`;
  }

  /* Fonction permettant de compléter les fichiers PDF lorsqu'il manque des caractères à la fin */

  repairFileBase64(file) {
    let stringLength = file.length;
    while (stringLength % 4 != 0) {
      file += '=';
      stringLength++;
    }

    return file;
  }


  async presentToast(message?: string) {
    const generalErrorMessage = `Veuillez réessayer en ne dépassant pas la limite totale de 50Mo ou rapprochez-vous de votre conseiller.`;
    const toast = await this.toastController.create({
      message: message || generalErrorMessage,
      duration: 7000,
      position: 'top',
      cssClass: 'error-toast',
      buttons: [
        {
          icon: 'close',
          cssClass: 'button-color-changed',
          htmlAttributes: {
            'aria-label': 'close',
          },
        }
      ]
    });
    toast.present();
  }
}
