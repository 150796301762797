import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class IsLoggedInGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthenticationService
    ) {
    }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
  
      this.authService.isLoggedIn.subscribe((value) => {
          if (!value) {
            this.router.navigate(['/authentication']);
            localStorage.removeItem('user');
            return false;
          }
      });

      this.authService.checkJWTExpDate().then((value) => {
        if (!value) {
          this.router.navigate(['/authentication']);
          localStorage.removeItem('user');
        }
      });

      return true;
  }
}
