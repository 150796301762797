import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone, EventEmitter, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {
  @Output() loader = new EventEmitter<boolean>();
  private previousErrors: string[] = [];

  constructor(
    private zone: NgZone,
    private http: HttpClient,
    private platform: Platform,
    private authentication: AuthenticationService

  )
  {}
  handleError(error: any): void {
    const currentError = error.toString();
    if (this.previousErrors.indexOf(currentError) === -1) {
    if (error instanceof HttpErrorResponse && error.url.match(/api\/app-log$/)) {
      return;
    }
    this.zone.run(() => {
      this.logError(error);
    });
    this.previousErrors.push(currentError);
  }
    this.loader.emit(false);
    // console.error(error);
  }

  logError(error: any) {
    const platforms = this.platform.platforms().join(', ');
    const url = environment.cafpi.base_url + '/api/app-log';
    const user = JSON.parse(localStorage.getItem('user')) as any;
    const log = {
      type: 'error',
      message: `[${platforms}] Une erreur s\'est produite`,
      data: {
        app_error: error.message || error,
        user: user && user.user ? user.user.upn : null
      }
    };
    this.http.post(url, log, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        Authorization: 'Bearer ' + environment.cafpi.auth.authToken
      }
    }).subscribe((data) => {
    });
  }
}
