// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  params: {
    maxUploadSize: 52428800, // 50Mo
    appDownloadTimeout: 864000000, // 10 days
    privilegeTimeout: 7776000000 // 90 days
  },
  google_maps: {
    apiKey: 'AIzaSyCmhnH4O5AAKra7DsL_Jy2ZTZds2Q7sj4E',
    webApiKey: 'AIzaSyD3-V8Ik6yEWUY_CkG-lQUIpKiHNXcun3U'
  },
  firebase: {
    apiKey: 'AIzaSyD3-V8Ik6yEWUY_CkG-lQUIpKiHNXcun3U',
    authDomain: 'cafpi-espace-client.firebaseapp.com',
    databaseURL: 'xxxxxxxxxxxxxxxxxxxxxxxx',
    projectId: '896906717065',
    storageBucket: 'xxxxxxxx',
    messagingSenderId: 'xxxxxx',
    appId: 'cafpi-espace-client',
    measurementId: 'xxxxxxxxxxxxxxxx'
  },
  cafpi: {
    base_url: 'https://espaceclient-api.cafpi.fr',
    app_version: {
      url: '/app-version'
    },
    sponsorship: {
      url: '/api/sponsor'
    },
    auth: {
      register: {
        url: '/api/register'
      },
      login: {
        url: '/api/login_check'
      },
      authToken: 'wEBRW6zwbBmQ41BVjCEM0Zch5MqGXYyX',
    },
    lost_password: {
      check_email: '/api/forgotten-password',
      check_verification_code: '/api/check-verification-code',
      update_password: '/api/update-password'
    }
  }
};

// authDomain: "PROJECT_ID.",
//   databaseURL: "https://PROJECT_ID.firebaseio.com",

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
