import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import {environment} from 'src/environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  VERSION_NUMBER = '1.0.11';

  constructor(
    private commonService: CommonService,
    private appVersion: AppVersion,
    private http: HttpClient,
  ) { }

  checkVersion() {
    // on considere que la version web n'a pas besoin de contrôle de version
    if (!this.commonService.isNative()) {
      return Promise.resolve(true);
    }
    let isInf = false;
    return new Promise(async (resolve, reject) => {
      this.http.get(environment.cafpi.base_url + environment.cafpi.app_version.url).toPromise().then((data: any) => {
        const apiVersion = data.app_version.split('.');
        const localVersion = this.VERSION_NUMBER.split('.');

        apiVersion.forEach((element, key) => {
          // tslint:disable-next-line: radix
          if (!localVersion[key] || parseInt(localVersion[key]) < parseInt(element)) {
            reject(false);
            isInf = true;
            return;
          }
          if (parseInt(localVersion[key]) > parseInt(element)) {
            resolve(true);
          }
        });
        if (!isInf) {
          resolve(true);
        }
    });
  });
}
}
