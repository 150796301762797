import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IsLoggedInGuard } from './guards/is-logged-in-guard';

const routes: Routes = [
{
  path: 'authentication',
  loadChildren: () => import('./authentication/authentication.module').then( m => m.AuthenticationPageModule)
}, {
  path: 'authentication/password',
  loadChildren: () => import('./authentication/authentication.module').then( m => m.AuthenticationPageModule)
}, {
  path: 'warning/:id',
  loadChildren: () => import('./warning/warning.module').then( m => m.WarningPageModule)
}, {
  path: 'projects/:id',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'projects/:id/details',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'projects/:id/avis',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'projects/:id/document/:ref',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
    path: 'projects/requestedDocument',
    loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
    canActivate: [IsLoggedInGuard]
  }, {
    path: 'projects/:id/typeDocument',
    loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
    canActivate: [IsLoggedInGuard]
  }, {
  path: 'projects/:id/rdv/:ref',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'sponsorship',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'consultant',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'consultant/post/:id',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'faq',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'account',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'article/:id',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'video/:id',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'warning/:id/:ref',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: 'conditions-generales',
  loadChildren: () => import('./common/common.module').then( m => m.CommonPageModule),
  canActivate: [IsLoggedInGuard]
}, {
  path: '',
  redirectTo: '/authentication',
  pathMatch: 'full'
}];

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule{}
